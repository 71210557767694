export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function urlParser(url) {
    // Create a URL object
    let urlObj = new URL(url);

    // Create URLSearchParams object
    let params = new URLSearchParams(urlObj.search);

    // Add query parameters
    params.append('timestamp', Date.now().toString());
    params.append('refresh_data',true);

    // Update the URL with the new parameters
    urlObj.search = params.toString();

    // Get the updated URL
    let newUrl = urlObj.toString();
    return newUrl;
}