<template>
    <div class="vue-credit-card" :class="className">
        <div class="card-form-and-image">
            <form id="cc_form">
                <div class="credit-card-form">
                    <div class="field">
                        <label for="name">{{ trans.name.label }}</label>
                        <input maxlength="40" name="name" id="name" type="text" :placeholder="trans.name.placeholder"
                            v-model="form.name" @blur="validation('')" >
                        <span class="error" v-if="errors&&errors['name']"> {{errors['name']}}</span>
                    </div>
                    <div class="field">
                        <label for="bankName">{{ trans.bankName.label }}</label>
                        <input type="text" name="bankName" id="bankName" ref="bank" pattern="[a-zA-Z ]*"
                         :placeholder="trans.bankName.placeholder" v-model="form.bankName" @blur="validation('')" >
                         <span class="error" v-if="errors&&errors['bankName']"> {{errors['bankName']}}</span>
                    </div>
                    <div class="field">
                        <label for="accountNumber">{{ trans.accountNumber.label }}</label>
                        <input type="text" name="accountNumber" id="accountNumber" ref="account" pattern="[0-9]*"
                            inputmode="numeric" :placeholder="trans.accountNumber.placeholder" v-model="form.accountNumber" @blur="validation('')" >
                        <span class="error" v-if="errors&&errors['accountNumber']"> {{errors['accountNumber']}}</span>
                    </div>
                    <div class="field">
                        <label for="routingNo">{{ trans.routingNumber.label }}</label>
                        <input type="text" name="routingNo" id="routingNo" ref="routing" pattern="[0-9]*"
                            inputmode="numeric" :placeholder="trans.routingNumber.placeholder" v-model="form.routingNumber" @blur="validation('')" >
                        <span class="error" v-if="errors&&errors['routingNumber']"> {{errors['routingNumber']}}</span>
                    </div>
                    <div class="field">
                        <label for="State">{{ trans.type.label }}</label>
                        <select id="State" name="state" :placeholder="trans.type.placeholder" v-model="form.type"
                            required>
                            <option value="">{{ trans.type.placeholder }}</option>
                            <option value="checking">Checking</option>
                            <option value="saving">Saving
                            </option>
                        </select>

                    </div>
                    <div class="field-group">
                        <div class="field">
                            <label for="Email">{{ trans.email.label }}</label>
                            <input maxlength="100" name="email" id="Email" type="text"
                                :placeholder="trans.email.placeholder" v-model="form.email" @blur="validation('')">
                                <span class="error" v-if="errors&&errors['email']"> {{errors['email']}}</span>
                            
                        </div>
                        <div class="field">
                            <label for="Address">{{ trans.address.label }}</label>
                            <input maxlength="100" name="address" id="Address" type="text"
                                :placeholder="trans.address.placeholder" v-model="form.address" @blur="validation('')">
                            <span class="error" v-if="errors&&errors['address']"> {{errors['address']}}</span>
                            
                        </div>
                    </div>
                    <div class="field-group">
                        <div class="field">
                            <label for="City">{{ trans.city.label }}</label>
                            <input maxlength="20" name="city" id="City" type="text"
                                :placeholder="trans.city.placeholder" v-model="form.city" @blur="validation('')">

                                <span class="error" v-if="errors&&errors['city']"> {{errors['city']}}</span>
                        </div>
                        <div class="field">
                            <label for="State">{{ trans.state.label }}</label>
                            <select id="State" name="state" :placeholder="trans.state.placeholder" v-model="form.state"
                                required>
                                <option value="">{{ trans.state.placeholder }}</option>
                                <option v-for="(state, index) in states" :key="index" :value="state.value">
                                    {{ state.text }}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="field-group">
                        <div class="field">
                            <label for="Country">{{ trans.country.label }}</label>
                            <select id="Country" name="country" :placeholder="trans.country.placeholder"
                                v-model="form.country" @change="getStatesList" required>
                                <option value="">Choose Country</option>
                                <option v-for="(country, index) in countriesList" :key="index" :value="country.code">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                        <div class="field">
                            <label for="ZipCode">{{ trans.zipCode.label }}</label>
                            <input maxlength="20" name="zipCode" id="ZipCode" type="text"
                                :placeholder="trans.zipCode.placeholder" v-model="form.zipCode" @blur="validation('')">
                            <span class="error" v-if="errors&&errors['zipCode']"> {{errors['zipCode']}}</span>
                        </div>
                    </div>
                    <div class="field">
                        <button type="button" @click="validation('clickButton')">
                            {{ buttonText }} {{ currency }} {{ amount }}
                        </button>
                    </div>

                </div>
            </form>
        </div>
        <div style="display: flex; align-items: center;">
            <img
              src="/assets/img/payments/sha.jpeg"
              alt="icon"
              class="icon"
              style="width: 120px; margin-right: 10px;"
            />
            <span>Safeguarded by SHA , ensuring the security of your sensitive information</span>
          </div>
        <!-- <div>
            Safeguarded by  <img
            :src="'/assets/img/payments/sha.jpeg'"
            alt="icon"
            class="icon" style="width:120px;"
          />, ensuring the security of your sensitive information.
        </div> -->
    </div>
</template>

<script>
import axios from 'axios';
var statesList = require(`@/components/payment-link/states.json`);

const countries = [
    {
        code: 'US', name: 'United States'
    },
    {
        code: 'CA', name: 'Canada'
    },
    {
        code: 'IN', name: 'India'
    }
];

const formDefaults = {
    name: '',
    accountNumber: '',
    routingNumber: '',
    type:'checking',
    bankName:'',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
};

const defaultTranslations = {
    name: {
        label: 'Name',
        placeholder: 'Full Name'
    },
    accountNumber: {
        label: 'Account Number',
        placeholder: 'Account Number'
    },
    routingNumber: {
        label: 'Routing Number',
        placeHolder: 'Routing Number'
    },
    type:{
        label:"Account Type",
        placeholder:"Account Type"
    },
    email: {
        label: 'Email',
        placeholder: 'Email'
    },
    address: {
        label: 'Address',
        placeholder: 'Address'
    },
    city: {
        label: 'City',
        placeholder: 'City'
    },
    state: {
        label: 'State',
        placeholder: 'State'
    },
    country: {
        label: 'Country',
        placeholder: 'Country'
    },
    zipCode: {
        label: 'Zip Code',
        placeholder: 'Zip Code'
    },
    bankName:{
        label:"Bank Name",
        placeholder:"Bank Name"
    }
}
const buttonContent = "Proceed to pay";
const buttonDefaultColor = "#04AA6D";
export default {
    props: {
        trans: {
            type: Object,
            default: () => (defaultTranslations)
        },
        direction: {
            type: String,
            default: 'column',
            validator(value) {
                return ['row', 'column'].includes(value)
            }
        },
        className: {
            type: String,
            default: ''
        },
        noCard: {
            type: Boolean,
            default: false
        },
        yearDigits: {
            type: Number,
            default: 4,
            validator(value) {
                return [2, 4].includes(value)
            }
        },
        countriesList: {
            type: Array,
            default: () => (countries)
        },
        buttonText: {
            type: String,
            default: () => (buttonContent)
        },
        buttonColor: {
            type: String,
            default: () => (buttonDefaultColor)
        },
        customer:{
            type: Object,
        },
        amount:{
            type: String,
        },
        currency:{
            type: String
        }
    },
    mounted() {
        this.mapCustomerInfo();
        this.getLocation();
    },
    data() {
        return {
            flipped: false,
            cardType: null,
            cardIcon: null,
            cardInnerIcon: null,
            color: 'grey',
            form: formDefaults,
            states: [],
            errors: [],
        };
    },
    methods: {
        async getStatesList() {
            this.states = this.getStates(this.form.country);
            this.form.state = '';
        },
        validation(submit='') {
            this.errors = [];
            for (const key in this.form) {
                if (this.isEmpty(this.form[key])) {
                    this.errors.push({key:`${key} is required.`});
                    this.errors[key]=`${key} is required.`;
                    continue;
                }

                switch (key) {
                    case 'email':
                        if (!this.isValidEmail(this.form[key])) {
                            this.errors.push({key:`Invalid email address.`});
                            this.errors[key]=`Invalid email address.`
                        }
                        break;
                    // case 'accountNumber':
                    //     if(!this.isValidAccountNumber(parseInt(this.form[key])))
                    //     {
                    //         this.errors.push("Invalid Account Number");
                    //     }
                    //     break;
                    // case 'routingNumber':
                    //     if(!this.isValidRoutingNumber(parseInt(this.form[key])))
                    //     {
                    //         this.errors.push('Routing number must contain only digits.');
                    //     }
                    //     break;
                    case 'accountNumber':
                    case 'routingNumber':
                    case 'name':
                    case 'address':
                    case 'city':
                    case 'state':
                    case 'country':
                    case 'zipCode':
                    case 'type':
                    case 'bankName':
                        // These fields just need to be non-empty
                        break;
                    default:
                    // this.errors.push( `No validation rule for ${key}.`);
                    console.log("No validation rule for "+key)
                }
            }
            if( this.errors && this.errors.length)
            {
                console.log(this.errors)
            }else{
                if(submit)
                {
                    console.log("Form is valid",submit);
                    this.$emit('change', Object.assign({}, {...this.$data.form,type:1}));
                }
            }
        },
        async getLocation() {
            try {
                if(this.customer?.country)
                {
                    let states=this.getStates(this.customer.country)
                    if(states.length)
                    {
                        this.form.country = this.customer.country;
                        this.form.state = this.customer.state;
                        this.states = this.getStates(this.customer.country);
                        this.form.zipCode = this.customer.zip_code;
                    }
                    else{
                        console.log("Invalid country code");
                        throw "Invalid country code";
                    }
                    
                }else{
                    console.log("Invalid Not provided ")
                    throw "Invalid Not provided ";
                }
            }
            catch (err) {
                console.log(err)
                let data = await axios.get('https://ipapi.co/json/');
                console.log(data.data)
                this.form.zipCode = data.data.postal;
                this.form.country = data.data.country_code;
                this.states = this.getStates(data.data.country_code);
                this.form.state = data.data.region_code;
                this.form.city = data.data.city;
            }
        },
        async mapCustomerInfo()
        {
           this.form.name = this.customer?.name;
           this.form.email = this.customer?.email;
           this.form.address = this.customer?.address;
           this.form.city = this.customer?.city;
        },
    },
    computed: {
        getStates() {
            return (state) => {
                return statesList[state] ? statesList[state] : [];
            }
        },
        isEmpty() {
            return (value) => {
                return value?value === '':true;

            }
        },
        isValidEmail() {
            return (email) => {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regex.test(email);
            }
        },
        isValidAccountNumber()
        {
            return (accountNumber)=>{
               return  accountNumber && !/^\d+$/.test(accountNumber);
            }
        },
        isValidRoutingNumber()
        {
            return (routingNumber)=>{
                return routingNumber && !/^\d+$/.test(routingNumber)
            }
        }

    },
    beforeDestroy() {
        this.form= formDefaults
    }
}
</script>

<style lang="scss">
:root {
    --primary-color: #04AA6D;
    --secondary-color: #FFFFFF;
    --font-size: 16px;
}

.vue-credit-card {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .card-form-and-image {
        display: flex;
        align-items: center;
        justify-content: center;

        .credit-card-form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            //max-width: 400px;
            width: 100% !important;
            padding: 20px;
            color: #707070;

            .field-group {
                display: flex;

                .field:first-child {
                    margin-right: 10px;
                }
            }

            .field {
                position: relative;
                width: 100%;
                margin: 10px 0;

                label {
                    padding-bottom: 5px;
                    font-size: 13px;
                }
                .error{
                    color:#d32f2f;
                }

                select,
                input {
                    box-sizing: border-box;
                    margin-top: 3px;
                    padding: 15px;
                    font-size: 16px;
                    width: 100%;
                    border-radius: 3px;
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: var(--primary-color);
                    background-color: var(--secondary-color);
                    background-clip: padding-box;
                    border: 1px solid var(--primary-color);
                    border-radius: 0.25rem;
                    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                }

                select:focus-visible,
                input:focus-visible {
                    outline: var(--primary-color) auto 1px;
                }

                button {
                    padding: 10px 16px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                    margin: 4px 2px;
                    transition-duration: 0.4s;
                    cursor: pointer;
                    border-radius: 25px;
                    background-color: var(--secondary-color);
                    color: var(--primary-color);
                    font-weight: bold;
                    border: 2px solid var(--primary-color);
                    width: 100%;
                }

                button:hover {
                    background-color: var(--primary-color);
                    color: var(--secondary-color);
                }
            }
        }
    }

    .ccicon {
        height: 38px;
        position: absolute;
        right: 6px;
        top: calc(50% - 9px);
        width: 60px;
    }

    .credit-card-image {
        width: 100%;
        max-width: 400px;
        max-height: 251px;
        height: 54vw;
        padding: 20px;
        perspective: 1000px;
    }

    #ccsingle {
        position: absolute;
        right: 15px;
        top: 20px;

        svg {
            width: 100px;
            max-height: 60px;
        }
    }

    .creditcard {
        width: 100%;
        max-width: 400px;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        cursor: pointer;

        .front,
        .back {
            position: absolute;
            width: 100%;
            max-width: 400px;
            backface-visibility: hidden;
            color: #47525d;
        }

        .back {
            transform: rotateY(180deg);
        }

        &.flipped {
            transform: rotateY(180deg);
        }

        svg#cardfront,
        svg#cardback {
            width: 100%;
            box-shadow: 1px 5px 6px 0px black;
            border-radius: 22px;
        }

        .lightcolor,
        .darkcolor {
            transition: fill .5s;
        }

        .lightblue {
            fill: #03A9F4;
        }

        .lightbluedark {
            fill: #0288D1;
        }

        .red {
            fill: #ef5350;
        }

        .reddark {
            fill: #d32f2f;
        }

        .purple {
            fill: #ab47bc;
        }

        .purpledark {
            fill: #7b1fa2;
        }

        .cyan {
            fill: #26c6da;
        }

        .cyandark {
            fill: #0097a7;
        }

        .green {
            fill: #66bb6a;
        }

        .greendark {
            fill: #388e3c;
        }

        .lime {
            fill: #d4e157;
        }

        .limedark {
            fill: #afb42b;
        }

        .yellow {
            fill: #ffeb3b;
        }

        .yellowdark {
            fill: #f9a825;
        }

        .orange {
            fill: #ff9800;
        }

        .orangedark {
            fill: #ef6c00;
        }

        .grey {
            fill: #bdbdbd;
        }

        .greydark {
            fill: #616161;
        }
    }

    #svgname {
        text-transform: uppercase;
    }

    #cardfront {
        .st2 {
            fill: #FFFFFF;
        }

        .st3 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 600;
        }

        .st4 {
            font-size: 54.7817px;
        }

        .st5 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }

        .st6 {
            font-size: 33.1112px;
        }

        .st7 {
            opacity: 0.6;
            fill: #FFFFFF;
        }

        .st8 {
            font-size: 24px;
        }

        .st9 {
            font-size: 36.5498px;
        }

        .st10 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 300;
        }

        .st11 {
            font-size: 16.1716px;
        }

        .st12 {
            fill: #4C4C4C;
        }
    }

    #cardback {
        .st0 {
            fill: none;
            stroke: #0F0F0F;
            stroke-miterlimit: 10;
        }

        .st2 {
            fill: #111111;
        }

        .st3 {
            fill: #F2F2F2;
        }

        .st4 {
            fill: #D8D2DB;
        }

        .st5 {
            fill: #C4C4C4;
        }

        .st6 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }

        .st7 {
            font-size: 27px;
        }

        .st8 {
            opacity: 0.6;
        }

        .st9 {
            fill: #FFFFFF;
        }

        .st10 {
            font-size: 24px;
        }

        .st11 {
            fill: #EAEAEA;
        }

        .st12 {
            font-family: 'Rock Salt', cursive;
        }

        .st13 {
            font-size: 37.769px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .vue-credit-card .card-form-and-image .credit-card-form .field-group {
        display:block !important;
    }
  }
</style>
