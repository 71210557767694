<template>
    <div id="paypal-button-container"></div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import systemAxios from "@/config/axios";
export default {
    props: ["pk", "currency", "customer", "description", "redirectionPath", "microservicePath", "pgAccountId", "txnAcId", "payAmount","data"],
    data() {
        return {
        };
    },
    mounted() {
        this.initPayPal();
    },
    methods: {
        async alert(msg, title = '', type = 'info') {
            Swal.fire({
                icon: type,
                title: title,
                text: msg,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            }).then((result) => {
                //need to add other required logic
                console.log(result)
            });
        },

        async initPayPal() {
            let paypalMethods = this;
            let apiUrl = this.microservicePath;
            let pgAccountId = this.pgAccountId;
            let txnAcId = this.txnAcId;
            let amount = this.payAmount;
            let currency = this.currency;
            window.paypal
                .Buttons({
                    style: {
                        shape: "rect",
                        layout: "vertical",
                        color: "gold",
                        label: "",
                    },
                    message: {
                        amount: amount,
                    },

                    async createOrder() {
                        try {
                            const response = await fetch(`${apiUrl}/paypal/orders?pgAccountId=${pgAccountId}&txnAcId=${txnAcId}`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    cart: [
                                        {
                                            id: "KodeFast",
                                            quantity: "1",
                                        },
                                    ],
                                    amount: amount,
                                    currency: currency
                                }),
                            });
                            const orderData = await response.json();
                            if (orderData.api_status) {
                                return orderData.id;
                            }
                            const errorDetail = orderData?.data?.log;
                            const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description}`
                                : orderData?.data?.res_message;
                            throw new Error(errorMessage);
                        } catch (error) {
                            paypalMethods.alert(`${error.message}`, 'Oops ....!', 'error');
                        }
                    },

                    async onApprove(data, actions) {
                        try {
                            const response = await fetch(
                                `${apiUrl}/paypal/${data.orderID}/capture?pgAccountId=${pgAccountId}&txnAcId=${txnAcId}`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            );

                            const orderData = await response.json();
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you message

                            const errorDetail = orderData?.data?.log;

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per
                                // https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                throw new Error(
                                    `${errorDetail.description} (${orderData.debug_id})`
                                );
                            } else if (!orderData.purchase_units) {
                                // throw new Error(JSON.stringify(orderData));
                                throw new Error("Payment Not done ")
                            } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                const transaction =
                                    orderData?.purchase_units?.[0]?.payments
                                        ?.captures?.[0] ||
                                    orderData?.purchase_units?.[0]?.payments
                                        ?.authorizations?.[0];

                                let params = {
                                    // ...payload,
                                    ...paypalMethods.description,
                                    status: "SUCCESS",
                                    txnDetails: {
                                        txn_id: transaction.id,
                                        gateway: "PAYPAL",
                                        paymentId: orderData.id,
                                        name: orderData.payer.name.given_name + ' ' + orderData.payer.name.surname,
                                        email: orderData.payer.email_address,
                                        phoneNumber: orderData.payer.mobile_number || '+919999999999',
                                        paymentSession: orderData.id,
                                        amount: transaction.amount.value || 0,
                                        amountRefunded: 0,
                                        description: "",
                                        paymentDate: moment.utc(),
                                        refundDate: '',
                                        status: transaction.status == "COMPLETED" ? 'PAID' : 'NOT PAID',
                                        paymentType: "PAYPAL", //paymentIntent?.method || "CARD",
                                        method: "PAYPAL", //paymentIntent?.method || '',
                                        currency: transaction.amount.currency_code || 'USD',
                                        authCode: transaction.id,
                                        authorizationId: transaction.id,
                                    }
                                }

                                let apiResponse = await systemAxios.post(`/updateRulesOfPayBtn`, params);
                                if (apiResponse.data) {
                                    paypalMethods.alert("Transaction Successful", '', 'success');
                                    setTimeout(() => {
                                        if (params.category === 'FORM_BUILDER') {
                                            // Refresh the form builder data
                                            paypalMethods.$bus.$emit(`closePopUPsAfterPayMentFromFormBuilder`);
                                            paypalMethods.$bus.$emit("refreshFormbuilderAfterPayment", paypalMethods.description);
                                            Swal.close();
                                        }
                                        else {
                                            window.location.href = params.location;
                                            Swal.close();
                                        }

                                    }, 3000);
                                }

                            }
                        } catch (error) {
                            console.error(error);
                            console.log(
                                `Sorry, your transaction could not be processed...<br><br>${error}`
                            );
                            paypalMethods.alert(`${error.message}`, 'Oops ....!', 'error');
                        }
                    },
                })
                .render("#paypal-button-container");
        }
    },
};
</script>